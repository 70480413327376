import React from "react";
import qs from "query-string";
import styled from "styled-components";
import Seo from "../../components/Seo";
import { generateRandomString } from "../../helpers/str";
import {
  SPOTIFY_ACCOUNTS_BASE_URL,
  SPOTIFY_CLIENT_ID,
  SPOTIFY_REDIRECT_URI,
  SPOTIFY_SCOPES,
} from "../../constants";
import "../../components/styles/main.css";

const Page = styled.div`
  min-height: 100vh;
  background: #000212;
  color: white;
`;

const Container = styled.div`
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
  text-align: center;
`;

const Main = styled.main`
  padding-top: 24vh;
`;

const Heading = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 55px;
  font-family: EuclidFlex-Semibold-WebTrial;
`;

const ContinueWithSpotifyBtn = styled.button`
  background: #0046ce;
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const DeleteAccountPage = () => {
  const onLogin = async () => {
    const queryStr = qs.stringify({
      response_type: "code",
      client_id: SPOTIFY_CLIENT_ID,
      scope: SPOTIFY_SCOPES.join(","),
      redirect_uri: SPOTIFY_REDIRECT_URI,
      state: generateRandomString(23),
    });
    window.location.href = `${SPOTIFY_ACCOUNTS_BASE_URL}/authorize?${queryStr}`;
  };

  return (
    <Page>
      <Seo title="Delete accounts" />

      <Container>
        <Main>
          <Heading>Log in to delete your account</Heading>

          <ContinueWithSpotifyBtn onClick={onLogin}>
            Continue with Spotify
          </ContinueWithSpotifyBtn>
        </Main>
      </Container>
    </Page>
  );
};

export default DeleteAccountPage;
